import React from "react";
import "./PageHeader.scss";
import "../../../theme/PageHeader.scss";
import { ImagePass } from "../Image/ImagePass";
import { Breadcrumbs } from "../Breadcrumbs";
import { decodeEntities } from "../../utils/helpers";

export const PageHeader = (props) => {
  const {
    headerTitle,
    headerSubTitle,
    headerBackgroundImage,
    location,
    compact = true,
  } = props;
  return (
    <section className={`header-page${compact ? " compact" : ""}`}>
      <div className="title-block">
        <div className="wrap">
          <div className="inner">
            <Breadcrumbs location={location} />
            {headerTitle && (
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: headerTitle }}
              />
            )}
            {headerSubTitle && (
              <p
                className="sub-title"
                dangerouslySetInnerHTML={{
                  __html: decodeEntities(headerSubTitle),
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div className="hero">
        {headerBackgroundImage ? (
          <ImagePass
            src={
              headerBackgroundImage.source_url
                ? headerBackgroundImage.source_url
                : headerBackgroundImage
            }
            className="background"
          />
        ) : (
          <div className="placeholder" />
        )}
      </div>
    </section>
  );
};
